import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import Navigation from '../navigation'
import { Helmet } from "react-helmet"

export default function IndexRoute(props: PageProps) {

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>U.I.WD. - Creative Studio</title>
          <meta property="og:site_name" content="U.I.WD. Creative Studio" />
          <meta property="og:title" content="U.I.WD. Creative Studio" />
          <link rel="icon" type="image/png" href="/favicon.ico"></link>
          <meta property="og:url" content="https://uiwd.co" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/share.png" />
          <meta name="twitter:title" content="U.I.WD Creative Studio" />
          <meta name="twitter:image" content="/share.png" />
          <meta name="twitter:url" content="https://uiwd.co" />
          <meta name="twitter:card" content="summary" />
          <meta name="description" content="U.I.WD. is a creative direction studio based in São Paulo. Specialising in Art Direction, Digital, Product, Print and Space."></meta>
        </Helmet>
      <Layout location={props.location}>
        <Navigation index></Navigation>
      </Layout>
    </>
  )
}
